/* DO NOT EDIT. THIS FILE IS AUTOGENERATED. RUN `yarn build:i18n` instead. */

export const locale = {
    "alphabet": "abcdefghijklmnopqrstuvwxyzäöüß",
    "article": {
        "source-article-link": "Zu diesem Artikel gibt es einen Quellartikel, der hier zu finden ist:",
        "sources-title": "Quellen",
        "references-title": "Literatur",
        "authors-title": "Autor*innen",
        "former-authors": "Frühere Autoren",
        "international-cooperation-title": "Frühere Autor*innen",
        "international-cooperation-content": "Die ursprüngliche Version dieses Artikels basiert auf einem entsprechenden Artikel im norwegischen hausärztlichen Online-Handbuch Norsk Elektronisk Legehåndbok (NEL)."
    },
    "audio-fallback": "Ihr Browser unterstützt keine Audiowiedergabe.",
    "back": "Zurück",
    "block-content": {
        "unknown-error": "Ein Fehler ist aufgetreten."
    },
    "breadcrumb": {
        "breadcrumbs": "Breadcrumb",
        "pathnames": {
            "/calculators": "Rechner",
            "/concurrent-logins": "Gleichzeitige Anmeldung",
            "/cookie-declaration": "Über Cookies",
            "/glossary": "Glossar",
            "/logged-out": "Abgemeldet",
            "/search": "Suche",
            "/travel-vaccinations": "Impfungen",
            "/important-updates": "Wichtige Aktualisierungen"
        }
    },
    "choose-a-country": "Wählen Sie ein Land",
    "click-here": "Klicke hier",
    "close-gallery": "Fotokarussell schließen",
    "close-snackbar": "Schließen",
    "close-popover": "Schließen",
    "content-menu": "Menü",
    "copy": {
        "false": "Kopieren Sie den Link",
        "true": "Link kopiert"
    },
    "course": {
        "title": "Kurs"
    },
    "404": {
        "title": "404",
        "subtitle": "Seite wurde nicht gefunden",
        "description": "Versuche nach Artikel zu suchen"
    },
    "example": "Beispiel",
    "external-link": {
        "checkbox-label": "Diesen Dialog nicht mehr anzeigen",
        "close": "Schließen",
        "title": "Externer Link"
    },
    "fallback-template-name": "Artikel",
    "favorite": {
        "favorites": "Favoriten",
        "latest": "letzte {{count}}",
        "add-to-favorites": "Zu Favoriten hinzufügen",
        "remove-from-favorites": "Aus Favoriten entfernen",
        "manage-all": "Alle anzeigen/bearbeiten",
        "none": "Noch keine Favoriten",
        "sort": {
            "abc": "Alphabetisch",
            "options": "Favoriten sortieren",
            "time": "Datum",
            "template": "Kategorien"
        },
        "sort-by": "Sortieren nach:"
    },
    "feedback": {
        "body": "Nachricht",
        "cancellation": "Kündigung des Abonnements",
        "editorial-feedback": "Nachrichten an die Redaktion",
        "email": "E-Mail",
        "error-sending-ticket": "Beim Senden der Anforderung ist ein Fehler aufgetreten. Kontaktieren Sie uns unter support@deximed.de",
        "forgotten-password": "Benutzername/Passwort vergessen",
        "general": "Allgemeine Anfrage",
        "groupId": "Worauf bezieht sich Ihre Anfrage?",
        "invoice": "Rechnung",
        "name": "Name",
        "order": "Bestellung",
        "price-quote": "Preisanfrage",
        "send": "Senden",
        "subject": "Thema",
        "ticket-sent": "Anfrage gesendet",
        "title": "Feedback geben / Anfrage senden"
    },
    "general-error": "Oh!",
    "glossary": {
        "header": "Da ist etwas schiefgegangen.",
        "helper": "Glossar: Geben Sie mindestens zwei Buchstaben ein oder drücken Sie einen Buchstaben",
        "search-label": "In Glossar suchen",
        "title": "Glossar (A-Z)"
    },
    "go-to-homepage": "Zurück zur Homepage",
    "header": {
        "feedback": "Feedback geben",
        "logo-alt": "Deximed",
        "notifications": "Mitteilungen",
        "profile": "Profil öffnen",
        "show-notifications": "{{amount}} Nachrichten anzeigen"
    },
    "home": "Home",

    "important-updates": {
        "important-update": "Wichtige Aktualisierung",
        "important-updates": "Wichtige Aktualisierungen"
    },
    "last-approved-by": "Zuletzt revidiert von",
    "last-revised": "Zuletzt revidiert",
    "last-updated": "Zuletzt bearbeitet",
    "revision-interval": "Revisionsinterval",

    "layouts": {
        "article-skeleton": "Wird geladen",
        "error": "Fehler",
        "unauthorized": "Nicht autorisiert"
    },
    "loading-please-wait": "Laden, bitte warten",
    "login": "Einloggen",
    "login-again": "Wieder einloggen",
    "logout": "Ausloggen",
    "megamenu": {
        "no-topics-found": "Keine Unterkapitel gefunden",
        "close": "Megamenü schließen",
        "open": "Megamenü öffnen"
    },
    "missing-description": "Fehlende Beschreibung",
    "new-abbreviation": "NEU",
    "new-articles": {
        "new-and-updated-articles": "Neue und aktualisierte artikel",
        "copy-as-html": {
            "false": "Als HTML kopieren",
            "true": "Kopiert",
            "tooltip": "Kopieren Sie den gesamten Artikel als HTML-Code"
        },
        "professional": "Fachartikel",
        "public": "Artikel öffnen",
        "search-in-articles": "Artikel suchen",
        "from-date": "Ab dem Datum",
        "type-of-articles": "Art der Artikel"
    },
    "next": "Nächster",
    "no-country": "Land nicht gefunden",
    "notifications": {
        "no-new-notifications": "Keine neue Nachrichten",
        "notification-was-removed": "Nachricht entfernt",
        "remove-notification": "Nachricht entfernen",
        "title": "Mitteilungen"
    },
    "on-print": {
        "copy-right": "Copyright © $year Bonnier Healthcare Germany.",
        "disclaimer": "Dies ist eine Abschrift eines Artikels von Deximed. Unsere allgemeinen Geschäftsbedingungen finden Sie auf unserer Website hier: https://deximed.de/informationen/haftungsauschluss-urheberrecht",
        "print-from-product": "Ausdruck von ",
        "printed-by": "gedruckt von",
        "video-placeholder": "Die Online-Version dieses Artikels enthält ein Video. Verwenden Sie den QR-Code oder die URL oben, um das Video in der Webversion anzusehen."
    },
    "open-section": {
        "false": "Abschnitt öffnen",
        "true": "Abschnitt schließen"
    },
    "outdated": {
        "bg-alt": "Logos aktueller Browser",
        "description": "Aus Sicherheitsgründen bitten wir Sie um ein Upgrade.",
        "link": "Sehen Sie hier die Optionen",
        "title": "Dies ist ein veralteter Browser"
    },
    "paywall": {
        "article-public-open-not-logged-in": {
            "title": "Sind Sie im Gesundheitswesen tätig?",
            "text": "Dieser Artikel ist für Patient*innen bestimmt. Melden Sie sich an oder schließen Sie ein Abo ab, um auf Inhalte für Berufstätige im Gesundheitswesen zuzugreifen."
        },
        "article-public-open-no-sub": {
            "title": "Bleiben Sie auf dem neuesten Stand!",
            "text": "Schließen Sie ein Abo ab, um Zugang zu Inhalten um auf Inhalte für Berufstätige im Gesundheitswesen zu erhalten."
        },
        "article-public-not-logged-in": {
            "title": "Sind Sie im Gesundheitswesen tätig?",
            "text": "Dieser Artikel ist für Patient*innen bestimmt. Melden Sie sich an oder schließen Sie ein Abo ab, um auf Inhalte für Berufstätige im Gesundheitswesen zuzugreifen."
        },
        "article-public-no-sub": {
            "title": "Bleiben Sie auf dem neuesten Stand!",
            "text": "Schließen Sie ein Abo ab, um Zugang zu Inhalten um auf Inhalte für Berufstätige im Gesundheitswesen zu erhalten."
        },
        "not-logged-in": {
            "title": "Bleiben Sie auf dem neuesten Stand!",
            "text": "Melden Sie sich an oder schließen Sie ein Abo ab, um fortzufahren."
        },
        "no-sub": {
            "title": "Bleiben Sie auf dem neuesten Stand!",
            "text": "Schließen Sie ein Abo ab, um weiterzulesen."
        },
        "close": "Schließen",
        "order": "Ein Abo abschließen"
    },
    "print": "Drucken",
    "product-name": "DEXIMED",
    "profile": {
        "logged-in-as-organisation": "Sie sind mit dem Organisationsbenutzer angemeldet:",
        "login-with-personal-account": "Wenn Sie ein persönliches Konto haben, melden Sie sich hier an",
        "choose-region": "Region wählen",
        "close": "Profil schließen",
        "color-mode": {
            "auto": "Auf automatisch umschalten",
            "dark": "Auf dunkel umschalten",
            "light": "Auf hell umschalten",
            "period": {
                "auto": "Abdunkeln zwischen {{from}} und {{to}}",
                "dark": "Dunkles Themenset",
                "light": "Helles Themenset"
            }
        },
        "contact": "Kontakt",
        "dictionary": {
            "toggle-mark-words": "Worterklärungen im Text anzeigen",
            "description": "Hier ist eine Erklärung des ausgewählten Wortes"
        },
        "edit": "Profil bearbeiten",
        "favorites": "Ihre Favoriten",
        "font-size": "Textgröße",
        "logged-out": {
            "login-button": "Einloggen",
            "login-text": ["Sie sind nicht eingeloggt.", ""],
            "login-title": "",
            "request-licenses": "Weitere Lizenzen anfragen",
            "send-request": "Anfrage absenden",
            "sending": "Senden...",
            "sending-failed": "Senden der Anfrage ist fehlgeschlagen",
            "example-email": "bespiel@bespiel.de",
            "type-email": "E-Mail-Adresse",
            "invalid-email": "Ungültige E-Mail-Adresse",
            "subscribe-button": "Abo bestellen",
            "subscribe-text": "Haben Sie Deximed noch nicht abonniert?",
            "request-subject": "Anfragen zu Lizenzen",
            "requester-text": "Die folgende E-Mail-Adresse fordert weitere Lizenzen in Deximed an:"
        },
        "notifications": "Mitteilungen",
        "title": "Profil",
        "verified": "Verifizierter Benutzer",
        "settings": "Einstellungen"
    },
    "published": "Veröffentlicht",
    "qr": {
        "body": "Verwenden Sie den QR-Code oder die Website-Adresse, um zur Online-Version des Artikels zu gelangen.",
        "header": "Online-Version des Artikels:"
    },
    "realtime-guideline": {
        "footer-tooltip": ""
    },
    "regional-content": {
        "default": "(Standard)",
        "no-content-for-region": "Dieser Artikel enthält keinen regionalen Inhalt für die folgende Region: {{region}}. Bitte wählen Sie eine andere Region.",
        "no-region": "Keine Region",
        "no-region-selected": "Keine Region ausgewählt. Bitte wählen Sie eine Region aus, um regionale Inhalte anzuzeigen.",
        "set-as-default": "Standardmäßig speichern"
    },
    "release-notes": "Versionshinweise",
    "reviewed": "In den letzten 90 Tagen überarbeitet",
    "reviewed-and-new": "Überarbeitete und neue Artikel",
    "revised-abbreviation": "REV",
    "saved": "Gespeichert",
    "search": {
        "all-hits": "Alle Suchergebnisse",
        "arrow-directions": {
            "arrow-up": "AUF ",
            "down": "AB ",
            "press": "Mit den Pfeiltasten in der Liste ",
            "and": "und ",
            "to-navigate": "navigieren"
        },
        "clear-search": "Suchfeld leeren",
        "default-tab-label": "Alle Treffer",
        "error": "Die Suche ist fehlgeschlagen. Bitte starten Sie eine neue Suche.Wenn das Problem auftritt, kontaktieren Sie uns bitte.",
        "filter-title": "Suche optimieren",
        "found-x-hits-for-query": "Suchergebnisse für '{{query}}' {{type}}",
        "history": "Suchverlauf",
        "improve-search": "Schlüsselwörter",
        "layout-title": "Suche",
        "new": "Neu",
        "no-hits": "Es wurden keine Ergebnisse zu Ihrer Suchanfrage gefunden. Modifizieren Sie Ihre Suchanfrage und versuchen Sie es erneut.",
        "no-hits-tips": [
            "Hinweise zur Suche:",
            "Überprüfen Sie die Schreibweise Ihres Suchbegriffs",
            "Versuchen Sie es mit einem Synonym oder alternativen Wörtern",
            "Versuchen Sie, einen allgemeineren Begriff einzugeben﻿"
        ],
        "no-hits-for-term": "Keine Übereinstimmungen für das Schlüsselwort '{{query}}'",
        "revised": "Rev",
        "search": "Suche",
        "search-placeholder": "Suche nach Symptomen, Krankheiten und mehr",
        "searching": "Sucht ...",
        "searching-please-wait": "Sucht, bitte warten ...",
        "show-more-hits": "Weitere Treffer anzeigen",
        "too-long": "Suchergebnisse für '{{query}}'. Die Suchanfrage ist auf maximal 30 Wörter begrenzt.",
        "fuzzy": "<strong>Keine Artikel stimmten mit Ihrer Suche nach '{{query}}' überein.</strong><br />Das untenstehende Suchergebnis beruht auf der Suche nach ähnlichen Wörtern wie den von Ihnen eingegebenen."
    },
    "share-article": "Teilen/QR",
    "show-more": "Mehr sehen",
    "show-navigation": "Navigation anzeigen",
    "show-reference": "Referenz anzeigen",
    "show-references": "Referenzen anzeigen",
    "show-tools": "Werkzeuge anzeigen",
    "site-name": "DEXIMED – Deutsche Experteninformation Medizin",
    "skip-to-main-content": "Zum Hauptinhalt springen",
    "tools": {
        "calculators": {
            "action": "Alles sehen",
            "title": "Scores, Tests, Fragebogen"
        },
        "checklists": {
            "action": "",
            "title": "Checklisten"
        },
        "courses": {
            "action": "Alle Kurse anzeigen",
            "title": "Kurs"
        },
        "show-more": "Mehr sehen",
        "show-less": "Weniger sehen",
        "loading": {
            "action": "",
            "content": "Bitte warten",
            "title": "Wird geladen"
        },
        "patient-information": {
            "action": "Alles sehen",
            "title": "Patienteninformationen"
        },
        "regional-content": {
            "action": "Siehe Inhalt",
            "content": "Dieser Artikel beschreibt regionale Routinen.",
            "title": "Regionaler Inhalt"
        },
        "related-articles": {
            "action": "",
            "title": "Verwandte Themen"
        },
        "title": "Tools"
    },
    "unauthenticated-request": "Sie müssen eingeloggt sein",
    "unauthorized-request": "Nicht autorisiert",
    "profession-popup": {
        "message": "Sie haben keinen Beruf / keine Spezialisierung angegeben. Bitte wählen Sie unten das Passende aus.",
        "profession-input-label": "Beruf",
        "speciality-input-label": "Spezialität",
        "submit-button": "Aktualisieren"
    },
    "meta": {
        "og-title-suffix": "Symptome, Diagnose und Behandlung"
    },
    "user-details": {
        "confirm": "",
        "success": "",
        "fill-and-confirm": "",
        "name": "",
        "email": "",
        "address": "",
        "zip": "",
        "city": "",
        "specialization-1": "",
        "specialization-2": "",
        "study-program": "",
        "profession": "",
        "profession-category": "",
        "work-location": "",
        "study-location": "",
        "hpr-number": "",
        "remind-me-later": "",
        "remind-me-later-text": "",
        "confirm-button": ""
    }
} as const